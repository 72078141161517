import { styled } from "styled-components";

export const Container = styled.header`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 2px 0 15px rgb(176 176 176 / 37%);
  padding: 10px 0;

  margin-bottom: 50px;
`;

export const SubContainer = styled.nav`
  width: 90%;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "logotipo ... menu";
  grid-template-rows: 60px auto;
  align-items: center;

  @media (min-width: 850px) {
    display: grid;
    grid-template-columns:
      auto minmax(300px, 1fr) minmax(auto, 100px) minmax(auto, 100px)
      minmax(auto, 100px);
    grid-template-areas: "logotipo search-bar home about contacts";
    align-items: center;
  }
`;

export const Logotipo = styled.h1`
  display: flex;

  font-family: Lexend, sans-serif;
  font-style: italic;
  font-size: 40px;
  font-weight: 700;
  text-shadow: rgb(140 250 199) 0px 0px 15px;
  color: #0f232e;

  cursor: pointer;

  & > span {
    font-family: Lexend, sans-serif;
    font-style: italic;
    font-size: 40px;
    font-weight: 700;
    text-shadow: rgb(140 250 199) 0px 0px 15px;
    color: #00eeff;
  }
`;

export const Button = styled.button`
  position: relative;
  background-color: transparent;

  font-size: 18px;
  font-weight: 700;
  color: #ffffff;

  padding: 15px 0;
  border: none;

  &:hover {
    color: #dfdfdf;
  }
`;

export const Menu = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;

  font-size: 36px;
  color: #dfdfdf;
`;
