import styled from "styled-components";

export const FooterTag = styled.footer`
  background-color: #313131;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  & > div {
    width: 90%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    & > p {
      font-family: Inter;
      font-size: 18px;
      color: white;

      & > a {
        font-family: Inter;
        font-size: 18px;
        color: white;
      }
    }
  }
`;
