import { RiWhatsappFill } from "react-icons/ri";
import { AZap } from "./style";

const WhatsApp = () => {
  return (
    <AZap href="https://contate.me/windkart" target="_blank" rel="noreferrer">
      <RiWhatsappFill />
    </AZap>
  );
};

export default WhatsApp;
