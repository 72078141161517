import * as S from "./style";
import social from "../../assets/IMG_20170120_120119052.jpg";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { useContext } from "react";
import { WindowContext } from "../../contexts/WindowContext";

const SocialMedia = () => {
  const { windowSize } = useContext(WindowContext);

  return (
    <S.Container>
      {windowSize > 600 && <S.Image src={social} alt="foto" />}
      <S.Info>
        <a
          href="https://www.instagram.com/windkartcarroavela/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram
          <AiFillInstagram />
        </a>
        <a
          href="https://www.facebook.com/windkartcarroavela"
          target="_blank"
          rel="noreferrer"
        >
          Facebook
          <AiFillFacebook />
        </a>
        <a
          href="https://www.youtube.com/user/leopipeiro/"
          target="_blank"
          rel="noreferrer"
        >
          Youtube
          <AiFillYoutube />
        </a>
      </S.Info>
    </S.Container>
  );
};

export default SocialMedia;
