import { css, styled } from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 50%;
  height: auto;

  object-fit: cover;
  object-position: center;

  border-radius: 10px 0 0 10px;

  @media (max-width: 1024px) {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
`;

export const Info = styled.nav`
  display: flex;
  flex-direction: column;

  background-color: rgb(255 255 255 / 37%);
  border-radius: 0 10px 10px 0;

  padding: 25px 25px 0 25px;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: row;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    padding: 25px 25px 10px 25px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    border-radius: 10px;
    justify-content: space-between;
  }

  & > a {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-family: Lexend;
    font-size: 26px;
    font-weight: 600;

    border: none;
    background-color: transparent;

    text-decoration: none;

    color: #ffffff;
    opacity: 0.7;

    &:hover {
      transition: 0.3s;
      opacity: initial;
    }

    & > svg {
      font-size: 140px;
    }
  }
`;
