import { styled } from "styled-components";

interface IStyledProps {
  ishamburguer: string;
}

export const Container = styled.nav<IStyledProps>`
  position: absolute;
  top: 80px;
  right: 5%;
  z-index: 1000;

  width: 200px;
  height: max-content;

  display: ${({ ishamburguer }) => (ishamburguer === "true" ? "flex" : "none")};
  justify-content: center;

  background-color: rgb(40 93 90);
  border-radius: 15px;
  box-shadow: 0px 5px 7px rgb(140 250 199);

  padding: 15px 0;
`;

export const SubContainer = styled.div`
  width: 80%;

  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  position: relative;
  border: none;
  background-color: transparent;

  font-size: 18px;
  font-weight: 700;

  color: #ffffff;

  padding: 15px 0;
`;

export const Menu = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;

  font-size: 36px;
  color: #ffffff;
`;
