import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  background-color: rgb(255 255 255 / 37%);
  border-radius: 10px;

  padding: 30px;
  gap: 100px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 30px;
  }

  & > span {
    width: 1px;
    border-left: 1px solid rgb(0 0 0 / 25%);

    @media (max-width: 900px) {
      width: 100%;
      border-bottom: 1px solid rgb(0 0 0 / 25%);
    }
  }
`;

export const Details = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 20px;

  & > h3 {
    width: 100%;

    font-family: Lexend;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: 202020;

    margin-bottom: 30px;
  }

  .categories {
    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 10px;
  }

  .subtitles {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;

    color: #292929;
  }

  .description {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;

    color: #202020;

    padding-left: 20px;
  }
`;

export const Description = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 20px;

  & > h3 {
    width: 100%;

    font-family: Lexend;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: 202020;

    margin-bottom: 30px;
  }

  & > p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;

    color: #202020;

    padding-left: 20px;
  }
`;
