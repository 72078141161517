import { styled } from "styled-components";

export const AZap = styled.a`
  position: fixed;
  top: 85%;
  right: 5%;
  z-index: 1000;

  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  border-radius: 50%;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 70%);

  color: #00b366;
  font-size: 70px;

  &:hover {
    transition: all 0.3s;
    box-shadow: 2px 2px 10px rgba(60, 250, 60);
  }
`;
