import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Suspense, useContext, useRef, useState } from "react";
import { WindKart } from "./Models3d/WindKartCorSolida";
import { InstrutionsMobile } from "./InstructionsMobile";
import { InstrutionsDesktop } from "./InstructionsDesktop";
import { RiFileList2Line } from "react-icons/ri";
import ShowCase from "../ShowCase";
import { WindowContext } from "../../contexts/WindowContext";

export const Three = () => {
  const { windowSize } = useContext(WindowContext);
  const orbitControlsRef = useRef(null);
  const [isInstrutions, setIsInstrutions] = useState(true);

  return (
    <ShowCase title={"Kart clássico 3D"}>
      {isInstrutions ? (
        <>
          {windowSize < 1024 ? (
            <InstrutionsMobile setIsInstrutions={setIsInstrutions} />
          ) : (
            <InstrutionsDesktop setIsInstrutions={setIsInstrutions} />
          )}
        </>
      ) : (
        <>
          <button
            onClick={() => setIsInstrutions(true)}
            style={{
              position: "absolute",
              top: "117px",
              right: "10px",
              zIndex: "100",
              fontSize: "42px",
              backgroundColor: "transparent",
              border: "none",
              color: "white",
            }}
          >
            <RiFileList2Line />
          </button>
          <Canvas
            className="canvas"
            style={{
              width: "100%",
              height: "80vh",
              backgroundColor: "#0000001a",
              zIndex: 0,
            }}
          >
            <Suspense fallback={null}>
              <PerspectiveCamera
                makeDefault
                position={[-350, 60, 0]}
              ></PerspectiveCamera>
              <OrbitControls
                ref={orbitControlsRef}
                minPolarAngle={(Math.PI / 180) * 30}
                maxPolarAngle={(Math.PI / 180) * 160}
              />
              <directionalLight intensity={1} />
              <ambientLight intensity={0.5} />
              <WindKart position={[-95, -40, 130]}></WindKart>
            </Suspense>
          </Canvas>
        </>
      )}
    </ShowCase>
  );
};
