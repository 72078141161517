import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  justify-content: center;

  @media (max-width: 550px) {
    flex-direction: column;
  }

  #container-image {
    display: flex;
    width: 60%;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    @media (max-width: 550px) {
      width: 100%;
      flex-direction: row;
    }
  }

  .image {
    width: 100%;
    min-width: 230px;
    height: 550px;

    image-rendering: optimizeQuality;
    object-fit: cover;

    border-radius: 10px 0 0 10px;

    @media (max-width: 1024px) {
      height: 400px;
      border-radius: 10px 0 0 0;
    }

    @media (max-width: 550px) {
      height: auto;
    }
  }

  .list-images {
    width: 250px;
    height: 550px;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: auto;

    background-color: rgb(255 255 255 / 37%);

    @media (max-width: 1024px) {
      width: 100%;
      height: 150px;

      flex-direction: row;

      border-radius: 0 0 0 10px;
    }

    @media (max-width: 550px) {
      width: 40%;
      height: auto;
      flex-direction: column;
      border-radius: 0 10px 0 0;
    }

    & > img {
      width: 100%;

      @media (max-width: 1024px) {
        width: 200px;
      }

      @media (max-width: 550px) {
        width: 100%;
        height: auto;
      }
    }
  }
`;

export const ContainerInfo = styled.aside`
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: rgb(255 255 255 / 37%);

  border-radius: 0 10px 10px 0;

  padding: 30px 10px;

  @media (max-width: 1024px) {
    width: 70%;
  }

  @media (max-width: 550px) {
    width: 100%;
    border-radius: 0 0 10px 10px;
    gap: 30px;
  }

  & > .name {
    font-family: Lexend;
    font-size: 32px;
    color: #202020;
    text-align: center;

    @media (max-width: 750px) {
      font-size: 26px;
    }
  }

  & > .category {
    position: absolute;
    top: 15px;
    left: 15px;

    border-radius: 8px;
    background-color: #37b6a0;
    padding: 5px 20px;

    font-family: Lexend;
    font-size: 20px;
    color: #ffffff;
  }

  & > section {
    display: flex;
    gap: 20px;

    .price {
      font-size: 52px;
      font-family: "Inter";
      color: #399939;
      font-weight: 600;

      @media (max-width: 930px) {
        font-size: 36px;
      }

      @media (max-width: 600px) {
        font-size: 28px;
      }
    }
  }

  .container-buy {
    display: flex;
    gap: 15px;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .amount {
    display: flex;
    justify-content: space-between;

    background-color: #f0f0f0;
    border: 1px solid #888888;
    border-radius: 10px;

    & > button {
      width: 50px;

      padding: 10px;

      background-color: transparent;
      border: none;
      border-radius: 10px;

      font-size: 26px;
      color: #888888;

      :hover {
        font-size: 24px;
      }
    }

    & > button:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }

    & > button:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }

    & > div {
      width: 25px;

      display: flex;
      justify-content: center;
      align-items: center;

      border: none;

      & > span {
        color: #303030;
        font-family: "Inter";
        font-size: 22px;
      }
    }
  }

  .buy {
    width: 150px;

    border: 2px solid #ffffff;
    border-radius: 10px;

    background-color: transparent;
    color: #ffffff;

    font-size: 20px;
    font-family: "Lexend";

    padding: 10px 0;

    transition: all 0.3s;

    &:hover {
      border: 2px solid #00c029;

      background-color: #00c049;
      color: #ffffff;
    }
  }

  .info-post {
    width: 90%;
    font-family: "Inter";
    color: #202020;
  }
`;
