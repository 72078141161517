import { useNavigate } from "react-router-dom";
import * as S from "./style";
import { IKarts } from "../../interfaces/IProducts/karts";

interface ICard {
  kart: IKarts;
}

const Card = ({ kart }: ICard) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <img className="image" src={kart.images[0].image_url} alt="Foto" />
      <S.SubContainer>
        <span className="category">{kart.type}</span>
        <h3 className="name">{kart.name}</h3>
        <span className="price">
          {Number(kart.price).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
        <button
          className="infos"
          onClick={() => navigate(`/detalhes/${kart.id}`)}
        >
          Ver detalhes
        </button>
      </S.SubContainer>
    </S.Container>
  );
};

export default Card;
