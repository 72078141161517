import contatos from "../assets/IMG_20170120_120119052.jpg";
import velas from "../assets/velas.jpg";
import windkartEsportivoPricipal from "../assets/windkart-esportivo-principal.jpeg";
import windkartEsportivo from "../assets/windkart-esportivo.jpeg";
import windkartEsportivoGrama from "../assets/windkart-esportivo-grama.jpeg";
import bolsa from "../assets/windkart-bolsa.jpeg";
import bolsaDentro from "../assets/windkart-bolsa-dentro.jpeg";
import windkartClassicoPrincipal from "../assets/windkart-classico-inox-principal.jpeg";
import windkartClassicoLateral from "../assets/windkart-classico-inox-lateral.jpeg";
import windkartClassicoPraia from "../assets/windkart-classico-inox-praia.jpeg";

export const announcements = [
  {
    id: "windkart-esportivo",
    type: "Alumínio",
    name: "Windkart - Esportivo",
    price: 8990.0,
    created_at: "",
    user_id: "",
    infos: [
      {
        id: "aaaa-aaaa-aaaa-aaaa",
        announcement_id: "aaaa-aaaa-aaaa-aaaa",
        description: [
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Direção",
            text: "Kart pilotado com os pés.",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Material chassi",
            text: "Alumínio ",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Rodas",
            text: "Aro Plástico super resistente com pneus maciços de 8 polegadas.",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Banco",
            text: "Lona cor preta.",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Mastro",
            text: "5m de altura em alumínio desmontável.",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Vela",
            text: "Polietileno de alta resistência.",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Medida do kart",
            text: "Entre rodas traseiras (1.50m) e entre eixos traseiros e dianteiros (1.60m). ",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Peso total do kart",
            text: "28 kg.",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Peso suportado",
            text: "No maxímo piloto com 120kg.",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
          {
            id: "aaaa-aaaa-aaaa-aaaa",
            category: "Acompanha o kart",
            text: "3 roldanas da marca Nautos, corda e Bolsa para guardar o kart. ",
            infos_id: "aaaa-aaaa-aaaa-aaaa",
          },
        ],
      },
    ],
    images: [
      {
        id: "aaaa-aaaa-aaaa-aaaa",
        image_url: windkartEsportivoPricipal,
        announcement_id: "aaaa-aaaa-aaaa-aaaa",
      },
      {
        id: "aaaa-aaaa-aaaa-aaaa",
        image_url: windkartEsportivo,
        announcement_id: "aaaa-aaaa-aaaa-aaaa",
      },
      {
        id: "aaaa-aaaa-aaaa-aaaa",
        image_url: windkartEsportivoGrama,
        announcement_id: "aaaa-aaaa-aaaa-aaaa",
      },
      {
        id: "aaaa-aaaa-aaaa-aaaa",
        image_url: bolsa,
        announcement_id: "aaaa-aaaa-aaaa-aaaa",
      },
      {
        id: "aaaa-aaaa-aaaa-aaaa",
        image_url: bolsaDentro,
        announcement_id: "aaaa-aaaa-aaaa-aaaa",
      },
    ],
  },
  {
    id: "windkart-banco-duplo",
    type: "Aço-inox",
    name: "Windkart - Banco duplo",
    price: 15990.0,
    created_at: "",
    user_id: "",
    infos: [
      {
        id: "cccc-cccc-cccc-cccc",
        announcement_id: "cccc-cccc-cccc-cccc",
        description: [
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Direção",
            text: "Kart pilotado nos pés com (pedaleira) ou pilotado com as mãos (direção). Opção do cliente escolher.",
            infos_id: "cccc-cccc-cccc-cccc",
          },
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Material chassi",
            text: "Aço inoxidável",
            infos_id: "cccc-cccc-cccc-cccc",
          },
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Rodas",
            text: "Aro de alumínio, pneus inflaveis 8 polegadas.",
            infos_id: "cccc-cccc-cccc-cccc",
          },
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Banco",
            text: "Lona super resistente largura 1,20 metro total.",
            infos_id: "cccc-cccc-cccc-cccc",
          },
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Mastro",
            text: "5m de altura em alumínio desmontável.",
            infos_id: "cccc-cccc-cccc-cccc",
          },
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Vela",
            text: "Polietileno de alta resistência.",
            infos_id: "cccc-cccc-cccc-cccc",
          },
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Medida do kart",
            text: "Entre rodas traseiras (1.50m) e entre eixos traseiros e dianteiros (1.90m).",
            infos_id: "cccc-cccc-cccc-cccc",
          },
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Peso total do kart",
            text: "36 kg.",
            infos_id: "cccc-cccc-cccc-cccc",
          },
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Peso suportado",
            text: "No maxímo piloto + carona = 150kg.",
            infos_id: "cccc-cccc-cccc-cccc",
          },
          {
            id: "cccc-cccc-cccc-cccc",
            category: "Acompanha o kart",
            text: "3 roldanas da marca Nautos, corda e Bolsa para guardar o kart. ",
            infos_id: "cccc-cccc-cccc-cccc",
          },
        ],
      },
    ],
    images: [
      {
        id: "cccc-cccc-cccc-cccc",
        image_url: windkartClassicoPrincipal,
        announcement_id: "cccc-cccc-cccc-cccc",
      },
      {
        id: "cccc-cccc-cccc-cccc",
        image_url: windkartClassicoLateral,
        announcement_id: "cccc-cccc-cccc-cccc",
      },
      {
        id: "cccc-cccc-cccc-cccc",
        image_url: windkartClassicoPraia,
        announcement_id: "cccc-cccc-cccc-cccc",
      },
      {
        id: "cccc-cccc-cccc-cccc",
        image_url: bolsa,
        announcement_id: "cccc-cccc-cccc-cccc",
      },
      {
        id: "cccc-cccc-cccc-cccc",
        image_url: bolsaDentro,
        announcement_id: "cccc-cccc-cccc-cccc",
      },
    ],
  },
];

export const videos = [
  {
    id: "dddd-dddd-dddd-dddd",
    url: "https://www.youtube.com/embed/ihubln6SWaA",
  },
  {
    id: "eeee-eeee-eeee-eeee",
    url: "https://www.youtube.com/embed/rKb5JLxAYpA",
  },
];

export const abouts = [
  {
    id: "ffff-ffff-ffff-ffff",
    title: "Chassi",
    image: windkartEsportivoPricipal,
    infos: [
      {
        caption: "Contrução durável",
        text: "Os Windkarts foram construídos para suportar as condições adversas, como maresia, ventos fortes e exposição prolongada ao sol, garantindo uma durabilidade excepcional. Cada componente foi cuidadosamente selecionado e projetado para resistir aos efeitos corrosivos da maresia, evitando o desgaste prematuro e garantindo a integridade estrutural do veículo.",
      },
      {
        caption: "Design",
        text: "Cada linha e curva do Windkart foi meticulosamente concebida para otimizar a eficiência aerodinâmica excepcional. O engenhoso chassi em formato de Y não apenas eleva a velocidade do veículo, mas também proporciona ao piloto a capacidade de executar manobras radicais e arrojadas com maestria.",
      },
      {
        caption: "Tamanho compacto",
        text: "Nossos karts são transportados facilmente e podem ser embalados, incluindo mastro e vela, em sua própria bolsa de transporte que mede apenas 0,80 por 1,20 metros. Cada windkart também vem com instruções de montagem em questão de minutos sem nenhuma ferramenta especial. Isso significa que nossos velejadores podem colocar seus karts no porta-malas do carro.",
      },
    ],
  },
  {
    id: "ffff-ffff-ffff-ffff",
    title: "Velas",
    image: velas,
    infos: [
      {
        caption: "Contrução duravel",
        text: "Além disso, as velas utilizadas no Windkart são confeccionadas com materiais duráveis e resistentes, capazes de enfrentar as forças do vento e as condições climáticas variáveis. Sua construção meticulosa e materiais de alta qualidade garantem uma vida útil prolongada, minimizando a necessidade de substituições frequentes.",
      },
      {
        caption: "Mastro",
        text: "O mastro do Windkart, composto por cinco segmentos de alumínio, cada um medindo 1 metro de extensão, foi projetado para uma montagem e desmontagem práticas. Essa característica inteligente possibilita o armazenamento conveniente dentro da própria bolsa do veículo, facilitando o transporte e tornando a experiência ainda mais conveniente para os pilotos.",
      },
      {
        caption: "Acessórios",
        text: "O veículo vem equipado com três moitões de alta qualidade da renomada marca Nautos, proporcionando uma operação suave e eficiente. Além disso, são incluídos generosos 6 metros de corda ou cabo, garantindo ampla margem para diversas atividades. A retranca em alumínio, com 2 seguimentos de 1 metro que se encaixam, oferece robustez e durabilidade, contribuindo para a estabilidade e o desempenho notáveis do carro. ",
      },
    ],
  },
];

export const dataContacts = [
  {
    title: "Redes sociais",
    image: contatos,
    infos: [
      {
        name: "Instagram",
        link: "https://www.instagram.com/windkartkartavela/",
      },
      {
        name: "Facebook",
        link: "https://www.facebook.com/windkartkartavela",
      },
      {
        name: "Youtube",
        link: "https://www.youtube.com/user/leopipeiro/",
      },
    ],
  },
];
