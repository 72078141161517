import { createContext, useState } from "react";
import { IWindowContext } from "../../interfaces/IContexts/window";
import { IChildren } from "../../interfaces/generics";

export const WindowContext = createContext({} as IWindowContext);

const WindowProvider = ({ children }: IChildren) => {
  // Verifica se o componente showcase tem borda ou não ----------------------------------------
  const [isBorder, setIsBorder] = useState<boolean>(true);

  // Verifica a largura da pagina ----------------------------------------
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

  window.addEventListener("resize", function () {
    setWindowSize(window.innerWidth);
  });
  // ---------------------------------------------------------------------

  // Sobre até o topo da pagina ------------------------------------------
  const toUp = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  // ---------------------------------------------------------------------

  return (
    <WindowContext.Provider
      value={{
        windowSize,
        setWindowSize,
        toUp,
        isBorder,
        setIsBorder,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};

export default WindowProvider;
