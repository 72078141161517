import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import About from "../pages/About";
import Contacts from "../pages/Contacts";
import Details from "../pages/Details";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/sobre" element={<About />} />
      <Route path="/contatos" element={<Contacts />} />
      <Route path="/detalhes/:id" element={<Details />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default Routers;
