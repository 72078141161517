import { useNavigate, useParams } from "react-router-dom";
import { IKarts } from "../../interfaces/IProducts/karts";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Main from "../../components/Main";
import ShowCase from "../../components/ShowCase";
import ShoppingCart from "../../components/ShoppingCart";
import KartDetails from "../../components/KartDetails";
import React, { useContext, useEffect } from "react";
import { WindowContext } from "../../contexts/WindowContext";
import { announcements } from "../../services/mocks";

const Details = () => {
  const { id } = useParams();
  const { setIsBorder } = useContext(WindowContext);
  const navigate = useNavigate();
  useEffect(() => {
    setIsBorder(true);
  }, [setIsBorder]);

  return (
    <>
      <Header />
      <Main>
        {announcements?.map((kart: IKarts, index) => {
          if (kart.id.toString() === id) {
            return (
              <React.Fragment key={index}>
                <ShowCase title={"Carrinho de compras"}>
                  <ShoppingCart key={index} kart={kart} />
                </ShowCase>
                <ShowCase title={"Informações do kart"}>
                  <KartDetails key={index} kart={kart} />
                </ShowCase>
              </React.Fragment>
            );
          }
        })}
      </Main>
      <Footer />
    </>
  );
};

export default Details;
