import React from "react";
import { FooterTag } from "./style";

function Footer() {
  return (
    <FooterTag>
      <div>
        <p>
          ©2023 por{" "}
          <a href="https://www.instagram.com/projetoweboficial/">Projeto Web</a>
        </p>
        <p>Windkartcarroavela - CNPJ: 29.651.539/0001-92</p>
      </div>
    </FooterTag>
  );
}

export default Footer;
