import { useContext, useEffect } from "react";
import Routers from "./routes";
import { WindowContext } from "./contexts/WindowContext";
import WhatsApp from "./components/WhatsApp";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function App() {
  const { toUp } = useContext(WindowContext);
  const location = useLocation();

  // Volta para o topo ao atualizar a página.
  useEffect(() => {
    toUp();
  }, [location, toUp]);

  return (
    <div
      className="App"
      style={{
        backgroundImage:
          "linear-gradient(to bottom,#225855,#7eb6af,#b67c4f,#333333)",
      }}
    >
      <Helmet>
        <title>Windkart - Carro a vela</title>
        <meta
          name="description"
          content="Bem-vindo ao Windkart, onde você encontra karts (carros) movidos a vento de alta performance."
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://www.windkart.com.br" />
      </Helmet>
      <Routers />
      <WhatsApp />
    </div>
  );
}

export default App;
