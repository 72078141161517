import { ReactNode, useContext } from "react";
import * as S from "./style";
import { WindowContext } from "../../contexts/WindowContext";

interface IShowCase {
  children: ReactNode;
  title: string;
}

const ShowCase = ({ title, children }: IShowCase) => {
  const { isBorder } = useContext(WindowContext);
  return (
    <S.Container>
      <div className="title">
        <span></span>
        <h2>{title}</h2>
        <span></span>
      </div>

      <S.SubContainer $isborder={isBorder.toString()}>
        {children}
      </S.SubContainer>
    </S.Container>
  );
};

export default ShowCase;
