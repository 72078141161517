import { useContext, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Main from "../../components/Main";
import PhotoInfo from "../../components/PhotoInfo";
import ShowCase from "../../components/ShowCase";
import { IAbout } from "../../interfaces/ISection/about";
import { WindowContext } from "../../contexts/WindowContext";
import { abouts } from "../../services/mocks";

const About = () => {
  const { setIsBorder } = useContext(WindowContext);
  useEffect(() => {
    setIsBorder(true);
  }, [setIsBorder]);

  return (
    <>
      <Header />
      <Main>
        {abouts?.map((info: IAbout, index) => {
          return (
            <ShowCase title={info.title} key={index}>
              <PhotoInfo infos={info.infos} image={info.image} index={index} />
            </ShowCase>
          );
        })}
      </Main>
      <Footer />
    </>
  );
};

export default About;
