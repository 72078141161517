import { createContext, useState } from "react";
import { IHamburguerContext } from "../../interfaces/IContexts/hamburger";
import { IChildren } from "../../interfaces/generics";

export const HamburguerContext = createContext({} as IHamburguerContext);

const HamburguerProvider = ({ children }: IChildren) => {
  const [isHamburguer, setIsHaburguer] = useState<boolean>(false);

  const verifyIsHamburguer = () => {
    if (isHamburguer) {
      setIsHaburguer(false);
    } else {
      setIsHaburguer(true);
    }
  };
  return (
    <HamburguerContext.Provider
      value={{ isHamburguer, setIsHaburguer, verifyIsHamburguer }}
    >
      {children}
    </HamburguerContext.Provider>
  );
};

export default HamburguerProvider;
