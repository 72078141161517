import styled from "styled-components";

export const Mobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background-color: #ccfff326;
  border: 1px solid #22ffff38;
  border-radius: 15px;
  box-shadow: 3px 3px 15px #2f2f2f40;

  padding: 10px 10px 30px 10px;
  margin: auto;

  & > span {
    width: 72.35%;
    & > button {
      color: white;
      background-color: transparent;
      border: none;
      padding: 2px;
      width: 40px;

      position: relative;
      top: -0px;
      margin-left: 104%;
    }
  }

  & > h3 {
    color: #ffffff;
    font-family: Lexend;
    text-align: center;
    margin-bottom: 15px;
    font-size: 36px;
    margin-bottom: 40px;
  }

  & > section {
    width: 100%;
    height: 80vh;

    overflow-y: scroll;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > h4 {
        margin-left: 20px;
        margin-right: auto;
        font-family: Inter;
        color: #2f2f2f;
        font-size: 18px;
        margin-bottom: 15px;
      }
      & > p {
        font-size: 18px;
        text-align: initial;
        color: white;
        margin-left: 15px;
        margin-bottom: 15px;
      }
      & > img {
        border-radius: 10px;
        width: 90%;
        max-width: 325px;
        margin: 10px 5% 50px 5%;
      }
    }
  }

  & > button {
    color: white;
    font-size: 30px;
    font-weight: 700;
    background-color: #333333;
    border-radius: 50px;
    padding: 2px;
    width: 40px;

    position: relative;
    top: -3px;
    right: -87%;
  }
`;
