import { css, styled } from "styled-components";

interface IStyleProps {
  $isborder: string;
}

export const Container = styled.section`
  width: 90%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 60px;
  margin: 0 0 60px 0;

  .title {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > h2 {
      min-width: max-content;

      font-family: Lexend;
      font-size: 42px;
      font-weight: 600;
      color: white;
      text-shadow: 2px 2px 10px #202020;

      margin: 0 30px;

      @media (max-width: 1024px) {
        font-size: 36px;
      }

      @media (max-width: 500px) {
        width: fit-content;
        min-width: auto;
      }
    }

    & > span {
      padding: 0 50%;
      border-bottom: 1px solid #ffffff;
      opacity: 0.5;

      @media (max-width: 650px) {
        display: none;
      }
    }
  }
`;

export const SubContainer = styled.div<IStyleProps>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${({ $isborder }) =>
    $isborder === "true"
      ? css`
          border: 1px solid #5cffe359;
          border-radius: 10px;
          box-shadow: 2px 2px 10px #00000066;
        `
      : css`
          border: none;
          border-radius: none;
          box-shadow: none;
        `}

  gap: 20px;
`;
