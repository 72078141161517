import { useContext } from "react";
import { IDetails } from "../../interfaces/ISection/about";
import * as S from "./style";
import { WindowContext } from "../../contexts/WindowContext";

interface IProps {
  infos: IDetails[];
  image: string;
  index: number;
}

const PhotoInfo = ({ infos, image, index }: IProps) => {
  const { windowSize } = useContext(WindowContext);
  return (
    <S.Container>
      {index % 2 === 0 && windowSize > 1024 && (
        <S.Image src={image} $index={index} loading="lazy" />
      )}
      <S.Info $index={index} $windowsize={windowSize}>
        {infos.map((info: any, index) => {
          return (
            <div key={index}>
              <h3>{info.caption}</h3>
              <p>{info.text}</p>
            </div>
          );
        })}
      </S.Info>
      {index % 2 != 0 && windowSize > 1024 && (
        <S.Image src={image} $index={index} loading="lazy" />
      )}
    </S.Container>
  );
};

export default PhotoInfo;
