import styled from "styled-components";

export const Desktop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background-color: #ccfff326;
  border: 1px solid #22ffff38;
  border-radius: 15px;
  box-shadow: 3px 3px 15px #2f2f2f40;

  padding: 10px 10px 30px 10px;
  margin: auto;

  & > span {
    width: 72.35%;
    & > button {
      color: white;
      background-color: transparent;
      border: none;
      padding: 2px;
      width: 40px;

      position: relative;
      top: -0px;
      margin-left: 104%;
    }
  }

  & > h3 {
    color: #ffffff;
    font-family: Lexend;
    text-align: center;
    margin-bottom: 15px;
    font-size: 36px;
  }

  & > section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      border-radius: 7px;

      padding: 10px;

      & > h4 {
        font-family: Inter;
        color: #2f2f2f;
        font-size: 18px;
        margin-bottom: 15px;
      }
      & > p {
        font-family: Inter;
        font-size: 18px;
        text-align: initial;
        color: white;
        margin-left: 15px;
        margin-bottom: 15px;
      }
    }
  }
`;
