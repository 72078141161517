import { useContext } from "react";
import * as S from "./style";
import { HiMenuAlt3 } from "react-icons/hi";
import { HamburguerContext } from "../../contexts/HamburguerContext";
import { useNavigate } from "react-router-dom";

const Hamburguer = () => {
  const { isHamburguer, verifyIsHamburguer } = useContext(HamburguerContext);

  const navigate = useNavigate();
  return (
    <>
      <S.Menu className="menu" onClick={() => verifyIsHamburguer()}>
        <HiMenuAlt3 />
      </S.Menu>
      <S.Container ishamburguer={isHamburguer.toString()}>
        <S.SubContainer>
          <S.Button
            className="home"
            onClick={() => {
              navigate("/");
              verifyIsHamburguer();
            }}
            style={{ gridArea: "home" }}
          >
            Início
          </S.Button>
          <S.Button
            className="about"
            onClick={() => {
              navigate("/sobre");
              verifyIsHamburguer();
            }}
            style={{ gridArea: "about" }}
          >
            Sobre
          </S.Button>

          <S.Button
            className="contacts"
            onClick={() => {
              navigate("/contatos");
              verifyIsHamburguer();
            }}
            style={{ gridArea: "contacts" }}
          >
            Contatos
          </S.Button>
        </S.SubContainer>
      </S.Container>
    </>
  );
};

export default Hamburguer;
