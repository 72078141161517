import { css, styled } from "styled-components";

interface IStyleProps {
  $index: number;
  $windowsize?: number;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img<IStyleProps>`
  width: 30%;
  height: auto;

  object-fit: cover;
  object-position: bottom;

  border-radius: ${({ $index }) =>
    $index % 2 === 0 ? "10px 0 0 10px" : "0px 10px 10px 0px"};
`;

export const Info = styled.div<IStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: rgb(255 255 255 / 37%);
  border-radius: ${({ $index }) =>
    $index % 2 === 0 ? "0px 10px 10px 0px" : "10px 0 0 10px"};
  ${({ $windowsize }) =>
    $windowsize! < 1024 &&
    css`
      border-radius: 10px;
    `}

  padding: 25px;
  gap: 20px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > h3 {
      font-family: lexend;
      font-size: 22px;
      color: #2c2c2c;
    }
    & > p {
      font-family: Inter;
      font-size: 16px;
      color: #333333;
    }
  }
`;
