import * as S from "./style";
import { useContext } from "react";
import { WindowContext } from "../../contexts/WindowContext";
import { useNavigate } from "react-router-dom";
import Hamburguer from "../MenuHamburguer";

const Header = () => {
  const { windowSize } = useContext(WindowContext);

  const navigate = useNavigate();

  return (
    <>
      {/* DESKTOP ------------------------------------------------------------------- */}
      {windowSize > 850 ? (
        <S.Container>
          <S.SubContainer>
            <S.Logotipo onClick={() => navigate("/")}>
              Wind<span>Kart</span>
            </S.Logotipo>

            <S.Button
              className="home"
              onClick={() => navigate("/")}
              style={{ gridArea: "home" }}
            >
              Início
            </S.Button>
            <S.Button
              className="about"
              onClick={() => navigate("/sobre")}
              style={{ gridArea: "about" }}
            >
              Sobre
            </S.Button>
            <S.Button
              className="contacts"
              onClick={() => navigate("/contatos")}
              style={{ gridArea: "contacts" }}
            >
              Contatos
            </S.Button>
          </S.SubContainer>
        </S.Container>
      ) : (
        // MOBILE ----------------------------------------------------------------
        <S.Container>
          <S.SubContainer>
            <S.Logotipo>
              Wind<span>Kart</span>
            </S.Logotipo>

            <span className="..." style={{ gridArea: "..." }}></span>

            <Hamburguer />
          </S.SubContainer>
        </S.Container>
      )}
    </>
  );
};

export default Header;
