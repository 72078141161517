import { styled } from "styled-components";

export const Container = styled.div`
  max-width: 300px;
  min-width: 300px;

  display: grid;
  grid-template-columns: minmax(250px, 400px);
  grid-template-rows: 1fr 0.3fr;

  border: 1px solid #d9d9d9;
  border-radius: 10px;

  background-color: #efefef;

  .image {
    width: 100%;
    height: 240px;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
    object-position: center;
    image-rendering: optimizeSpeed;
  }
`;

export const SubContainer = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "category category" "name name" "infos price";

  justify-content: center;
  padding: 15px;
  gap: 15px;

  .category {
    grid-area: category;
    width: fit-content;

    font-family: Lexend;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;

    background-color: #37b6a0de;

    padding: 2px 10px;

    border: none;
    border-radius: 7px;
  }
  .name {
    grid-area: name;

    text-align: center;
    font-family: Lexend;
    font-size: 20px;
    font-weight: 700;
    color: #2a2a2a;
  }
  .price {
    grid-area: price;
    width: max-content;

    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    color: #399939;
    text-align: end;
  }
  .infos {
    grid-area: infos;

    width: fit-content;

    border: none;
    border-bottom: 1px solid #305050;
    background-color: transparent;

    font-family: Inter;
    font-size: 16px;
    color: #305050;
  }
`;
