/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ModelPath from "./WindKartCorSolida.glb"

export function WindKart(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(ModelPath)
  return (
    <group {...props} dispose={null}>
      <group ref={group} position={[0, 70, -20]} scale={0.5} rotation={[0, (Math.PI / 180) * 140, 0]}>
        <mesh geometry={nodes.mesh_id5.geometry} material={materials['127']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id6.geometry} material={materials['129']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id7.geometry} material={materials['131']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id8.geometry} material={materials['133']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id9.geometry} material={materials['135']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id10.geometry} material={materials['137']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id11.geometry} material={materials['139']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id12.geometry} material={materials['141']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id13.geometry} material={materials['143']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id14.geometry} material={materials['145']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id15.geometry} material={materials['147']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id16.geometry} material={materials['149']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id17.geometry} material={materials['151']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id18.geometry} material={materials['153']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id19.geometry} material={materials['155']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id20.geometry} material={materials['157']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id21.geometry} material={materials['159']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id22.geometry} material={materials['161']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id23.geometry} material={materials['163']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id24.geometry} material={materials['165']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id25.geometry} material={materials['167']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id26.geometry} material={materials['169']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id27.geometry} material={materials['171']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id28.geometry} material={materials['173']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id29.geometry} material={materials['175']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
        <mesh geometry={nodes.mesh_id30.geometry} material={materials['177']} position={[118.75, -223.31, 384.8]} scale={[0.84, 1.7, 1.7]} />
      </group>
    </group>
  )
}

useGLTF.preload(ModelPath)
