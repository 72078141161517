import { useState } from "react";
import * as S from "./style";
import { IKarts } from "../../interfaces/IProducts/karts";

interface IProps {
  kart: IKarts;
}

const ShoppingCart = ({ kart }: IProps) => {
  const [mainImageIndex, setMainImageIndex] = useState<number>(0);

  return (
    <>
      {/* Container que exibe a imagem do kart */}
      <S.Container>
        <div id="container-image">
          <img
            className="image"
            src={kart?.images[mainImageIndex].image_url}
            alt="imagem do kart"
          />
          <div className="list-images">
            {kart.images.map((image, index) => {
              return (
                <img
                  key={index}
                  src={image.image_url}
                  onClick={() => setMainImageIndex(index)}
                  style={{ cursor: "pointer" }}
                />
              );
            })}
          </div>
        </div>
        {/* Container com as informações do kart */}
        <S.ContainerInfo>
          <h1 className="name">{kart?.name}</h1>

          <p className="category">{kart.type}</p>

          <section>
            <span className="price">
              {Number(kart.price).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </section>

          <p className="info-post">
            {/* Informações sobre a postagem da compra */}
            <strong>
              Fale diretamento com a gente para realizar a compra deste kart e
              estipular o prazo de envio.
            </strong>
            <br />
            Entre em contato utilizando o botão do whatsapp a baixo a direita.
          </p>

          <p className="info-post">
            {/* Informações sobre a postagem da compra */}
            <strong>
              A postagem da compra será feita em até 5 dias úteis após a
              confirmação do pagamento
            </strong>
            , tanto para compras via Sedex ou Pac. O prazo escolhido pelo
            cliente só começa a contar após a postagem nos correios.
          </p>
        </S.ContainerInfo>
      </S.Container>
    </>
  );
};

export default ShoppingCart;
