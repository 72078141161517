import { IKarts } from "../../interfaces/IProducts/karts";
import * as S from "./style";

interface IProps {
  kart: IKarts;
}

const KartDetails = ({ kart }: IProps) => {
  return (
    <S.Container>
      <S.Description>
        <h3>Visão geral</h3>

        <p>
          O kart movido a vento é um veículo inovador, projetado para ser
          pilotado com os pés e impulsionado pela força do vento através de uma
          vela. Com um design moderno, esse kart oferece uma experiência única e
          emocionante para seus pilotos. <br />
          <br />O kart possui uma estrutura construída com materiais
          resistentes, garantindo leveza e durabilidade para melhor desempenho.
          <br />
          <br />
          Suas rodas robustas oferecem estabilidade e aderência ao solo,
          enquanto o assento proporciona conforto e segurança ao piloto. <br />
          <br />
          Equipado com um mastro ajustável, o kart suporta a vela e permite
          otimizar a performance em diferentes condições de vento. <br />
          <br />A vela é fabricada com material adequado para capturar a energia
          do vento de forma eficiente. <br />
          <br />
          Com medidas equilibradas para uma pilotagem ágil e dinâmica, o kart é
          fácil de manusear devido ao seu peso adequado. Projetado para suportar
          um piloto com peso padrão, torna-se adequado para uma ampla faixa de
          usuários. O kart movido a vento oferece uma maneira emocionante e
          sustentável de explorar o ambiente ao redor, aproveitando a energia
          natural do vento para uma condução divertida e amiga do meio ambiente.
        </p>
      </S.Description>
      <span></span>
      <S.Details>
        <h3>Fixa técnica</h3>

        {kart.infos[0].description.map((item, index) => {
          return (
            <div className="categories" key={index}>
              <h4 className="subtitles">{item.category}:</h4>
              <p className="description">{item.text}</p>
            </div>
          );
        })}
      </S.Details>
    </S.Container>
  );
};

export default KartDetails;
