import Header from "../../components/Header";
import ShowCase from "../../components/ShowCase";
import SocialMedia from "../../components/ SocialMedia";
import Main from "../../components/Main";
import Footer from "../../components/Footer";
import { useContext, useEffect } from "react";
import { WindowContext } from "../../contexts/WindowContext";

const Contacts = () => {
  const { setIsBorder } = useContext(WindowContext);
  useEffect(() => {
    setIsBorder(false);
  }, [setIsBorder]);

  return (
    <>
      <Header />
      <Main>
        <ShowCase title={"Redes sociais"}>
          <SocialMedia />
        </ShowCase>
      </Main>
      <Footer />
    </>
  );
};

export default Contacts;
