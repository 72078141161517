import { useContext, useEffect } from "react";
import { Three } from "../../components/Canvas";
import Card from "../../components/Card";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Main from "../../components/Main";
import ShowCase from "../../components/ShowCase";
import { WindowContext } from "../../contexts/WindowContext";
import { IKarts } from "../../interfaces/IProducts/karts";
import { announcements, videos } from "../../services/mocks";

const Dashboard = () => {
  const { setIsBorder, windowSize } = useContext(WindowContext);

  useEffect(() => {
    setIsBorder(false);
  }, [setIsBorder]);

  return (
    <>
      <Header />
      <Main>
        <ShowCase
          title={windowSize < 500 ? "Windkarts" : "Coleção de Windkart"}
        >
          {announcements?.map((kart: IKarts, index) => {
            return <Card key={index} kart={kart} />;
          })}
        </ShowCase>
        <ShowCase title="Vídeos">
          {videos?.map((video, index) => {
            return (
              <iframe
                key={index}
                width="560"
                height="315"
                src={video.url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
                loading="lazy"
              ></iframe>
            );
          })}
        </ShowCase>
        <Three />
      </Main>
      <Footer />
    </>
  );
};

export default Dashboard;
