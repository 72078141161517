import WindowProvider from "./WindowContext";
import HamburguerProvider from "./HamburguerContext";
import { IChildren } from "../interfaces/generics";

const Providers = ({ children }: IChildren) => {
  return (
    <WindowProvider>
      <HamburguerProvider>{children}</HamburguerProvider>
    </WindowProvider>
  );
};

export default Providers;
